import "./App.css";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import useSWR from "swr";
import Overlay from "./Overlay";
import Timer from "./Timer";
import Heart from "./Heart";
import useWindowDimensions from "./useWindowDimensions";
import { UUID } from "crypto";
import React from "react";
import ConnectionCounter from "./ConnectionCounter";

type IHeart = {
  x: number;
  y: number;
  direction: string;
  id: number;
  isRemote: boolean;
};

type ClickProps = {
  pageX: number;
  pageY: number;
};

function App() {
  const [enableSignalR, setEnableSignalR] = useState<boolean>(true);
  const [connectionIsActive, setConnectionIsActive] = useState<boolean>(false);
  const [connection, setConnection] = useState<HubConnection>();
  const [connectionCount, setConnectionCount] = useState<number>(1);
  const [myId, setMyId] = useState<UUID>();
  const [hearts, setHearts] = useState<IHeart[]>([]);
  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [hideOverlay, setHideOverlay] = useState<string>("");
  const [clickThroughOverlay, setClickThroughOverlay] =
    useState<boolean>(false);
  const [reSync, setReSync] = useState<boolean>(true);
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const { height, width } = useWindowDimensions();

  const apiUrl = "https://countdownapi.bananalisk.com";
  const fetcher = (url: string) => fetch(url).then((r) => r.json());
  const { data: serverSeconds, isLoading } = useSWR(
    reSync ? apiUrl : null,
    fetcher,
    {
      onSuccess: (data, key, config) => {
        if (hideOverlay === "") {
          setHideOverlay("hidden");
          setTimeout(() => setClickThroughOverlay(true), 1000);
        }
        const serverDate = new Date(data);
        const secondsLeft = Math.floor(
          (serverDate.getTime() - new Date().getTime()) / 1000
        );

        if (secondsLeft <= 0) {
          setReSync(false);
          setTotalSeconds(0);
        } else {
          setTotalSeconds(secondsLeft);
        }
      },
    }
  );

  useEffect(() => {
    if (enableSignalR) {
      const newConnection = new HubConnectionBuilder()
        .withUrl("https://signalr-image-czpyczldjq-lz.a.run.app/heartshub")
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build();

      setConnection(newConnection);
      setMyId(crypto.randomUUID());
    }
  }, [enableSignalR]);

  const addHeartLocally = useCallback((pageX: number, pageY: number) => {
    clearTimeout(timeoutIdRef.current);
    const left = Math.random() < 0.5;
    setHearts((hearts) => [
      ...hearts,
      {
        x: pageX,
        y: pageY,
        direction: left ? "Left" : "Right",
        isRemote: false,
        id: hearts.length,
      },
    ]);
    timeoutIdRef.current = setTimeout(() => {
      cleanUpHearts();
    }, 3000);
  }, []);

  const addRemoteHeart = useCallback((pageX: number, pageY: number) => {
    clearTimeout(timeoutIdRef.current);
    const left = Math.random() < 0.5;
    setHearts((hearts) => [
      ...hearts,
      {
        x: pageX,
        y: pageY,
        direction: left ? "Left" : "Right",
        isRemote: true,
        id: hearts.length,
      },
    ]);
    timeoutIdRef.current = setTimeout(() => {
      cleanUpHearts();
    }, 3000);
  }, []);

  useEffect(() => {
    if (connection && !connectionIsActive) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");
          setConnectionIsActive(true);
          connection.on("CreateHeart", (clientId, x, y) => {
            if (clientId !== myId) {
              const posX = x * width;
              const posY = y * height;
              addRemoteHeart(posX, posY);
            }
          });
          connection.on("UpdateConnectionCount", (connectionCount) => {
            setConnectionCount(connectionCount);
          });
        })
        .catch((e) => {
          console.log("Connection failed: ", e);
          setEnableSignalR(false);
        });
    }
  }, [
    connection,
    connectionIsActive,
    myId,
    addRemoteHeart,
    enableSignalR,
    height,
    width,
  ]);

  useEffect(() => {
    if(connection && connectionIsActive)
      {
        connection.off("CreateHeart");
        connection.on("CreateHeart", (clientId, x, y) => {
          if (clientId !== myId) {
            const posX = x * width;
            const posY = y * height;
            addRemoteHeart(posX, posY);
          }
        });
      }
  }, [connection, connectionIsActive, width, height, myId, addRemoteHeart]);

  useEffect(() => {
    if (isLoading || totalSeconds === 0) return;

    const interval = setInterval(() => {
      setTotalSeconds(totalSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isLoading, totalSeconds, serverSeconds, hideOverlay]);

  const cleanUpHearts = () => {
    setHearts([]);
  };

  const handleClick = ({ pageX, pageY }: ClickProps) => {
    if (enableSignalR && connection) {
      const x = pageX / width;
      const y = pageY / height;
      connection.invoke("HeartCreated", myId, x, y);
    }
    addHeartLocally(pageX, pageY);
  };

  return (
    <div onClick={handleClick}>
      <Overlay className={hideOverlay} clickThrough={clickThroughOverlay} />
      <div className="app">
        <div className="container">
          <h1 className="header">Countdown</h1>
          <Timer totalSeconds={totalSeconds} />
          {hearts.map((heart) => {
            return (
              <Heart
                key={heart.id}
                x={heart.x}
                y={heart.y}
                direction={heart.direction}
                isRemote={heart.isRemote}
              />
            );
          })}
        </div>
      </div>
      <ConnectionCounter connections={connectionCount}/>
    </div>
  );
}

export default App;
